import {createStore, applyMiddleware} from "redux";
import thunk from "redux-thunk";

import rootReducer from "./reducers/RootReducer";

let createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

export default createStoreWithMiddleware(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
