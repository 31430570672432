import {CHANGE_LANG} from "../actions/LanguageAction";

const DEFAULT_STATE = {
  language: "it",
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LANG:
      return {...state, language: action.payload};
    default:
      return state;
  }
};
