import React, {Component, Suspense} from "react";
import "./HamburgerIcon.scss";
import {imgUrl} from "../../shared/baseUrl";
import {withTranslation} from "react-i18next";

class HamburgerIcon extends Component {
  constructor(props) {
    super(props);
    this.onClickHandler = this.onClickHandler.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  onClickHandler() {
    const {
      showSideBarMenu,
      onShowMobileNavigationMenu,
      onHideMobileNavigationMenu,
    } = this.props;

    showSideBarMenu ? onShowMobileNavigationMenu() : onHideMobileNavigationMenu();
  }

  handleMenuClick(id) {
    const {changeView} = this.props;
    changeView(id === "mapButton" ? "map" : "details");
  }

  render() {
    const {view} = this.props;
    return (
      <div className="mobile-header">
        <div className="burger-container">
          <a className="burger-icon-link" onClick={this.onClickHandler} href="#">
            <span className="burger-span-element white-burger-icon-color"></span>
            <span className="burger-span-element white-burger-icon-color"></span>
            <span className="burger-span-element white-burger-icon-color"></span>
          </a>
          {/* <Image src={imgUrl + "pci_banner_small.gif"} size='small' alt="Logo protezione civile" /> */}
        </div>
        <div className="logo-mobile">
          <img
            src={imgUrl + "pci_banner_small.gif"}
            height="90%"
            alt="Logo protezione civile"
          />
        </div>

        <Suspense fallback="loading">
          <div className="view-button ui secondary menu">
            <a
              className={view === "details" ? "item active" : "item"}
              id="detailsButton"
              onClick={e => this.handleMenuClick("detailsButton")}
            >
              <i aria-hidden="true" className="list icon"></i>
              {/* {t("Statistiche")} */}
            </a>
            <a
              className={view === "map" ? " item active" : "item"}
              id="mapButton"
              onClick={e => this.handleMenuClick("mapButton")}
            >
              <i aria-hidden="true" className="globe icon"></i>
              {/* {t("Mappa")} */}
            </a>
          </div>
        </Suspense>
      </div>
    );
  }
}

export default withTranslation()(HamburgerIcon);
