import React, {Component, Suspense} from "react";
import echarts from "echarts";
// import {Domain} from "domain";
import "./Chart.scss";
// import {imgUrl} from "../../shared/baseUrl";

class Chart extends Component {
  constructor(props) {
    super(props);
    this.myChart = null;
    this.state = {
      // mapId: props.mapId,
      // mapName: props.mapName,
      // layerAdded: false,
      // layer1:{},
      // layer2:{},
      //mymap: {},
    };
  }

  updateDimensions() {
    this.myChart.resize();
  }

  componentDidUpdate() {
    let alarm = {
      grado1: 194.5,
      grado2: 194.85,
      grado3: 195.5,
      grado4_5: 195.75,
    };
    const {quotaLago, isLoading} = this.props;
    if (!isLoading) {
      // console.log(quotaLago.data);
      const timeX = quotaLago.data.map(el => el[0]);
      const quotaMisurata = quotaLago.data.map(el => el[2]);
      const quotaPrevista = quotaLago.data.map(el => el[1]);
      const fltrQuotaMisurata = quotaMisurata.filter(el => el !== "");
      const fltrQuotaPrevista = quotaPrevista.filter(el => el !== "");
      // console.log(fltrQuotaMisurata);
      let minMisurata = Math.min(...fltrQuotaMisurata);
      let minPrevista = Math.min(...fltrQuotaPrevista);
      let min = Math.min(minMisurata, minPrevista);
      let grado1arr = timeX.map(el => alarm.grado1);
      let grado2arr = timeX.map(el => alarm.grado2);
      let grado3arr = timeX.map(el => alarm.grado3);
      let grado4_5arr = timeX.map(el => alarm.grado4_5);

      let quotaMaxPrevista = {
        time:
          quotaLago.actual_quote >= quotaLago.max_fore_level
            ? quotaLago.model_run
            : quotaLago.time_max_fore_level,
        value:
          quotaLago.actual_quote >= quotaLago.max_fore_level
            ? quotaLago.actual_quote
            : quotaLago.max_fore_level,
      };

      // let min=Math.min(quotaMisurata);
      // console.log(minMisurata);
      // console.log(timeX);
      // console.log(quotaMisurata);
      // console.log(quotaPrevista);
      // console.log(quotaMaxPrevista);
      this.myChart.setOption(
        this.getOption(
          timeX,
          quotaMisurata,
          quotaPrevista,
          min,
          grado1arr,
          grado2arr,
          grado3arr,
          grado4_5arr,
          quotaMaxPrevista
        )
      );
      // this.myChart.addEventListener("click", this.updateZoom);
    }
    // const quotaMisurata = quotaLago.data.map(el => el[1]);
    // console.log(quotaMisurata);
  }
  componentDidMount() {
    const {fetchQuotaLago} = this.props;
    fetchQuotaLago();
    this.myChart = echarts.init(document.getElementById("chart1"));
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    // this.myChart.setOption(this.getOption())
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  // updateZoom = (event) => {
  //   console.log("ZOOOOM");
  // }

  convertDate(dataTime) {
    const {t} = this.props;
    let days = {
      0: t("Dom"),
      1: t("Lun"),
      2: t("Mar"),
      3: t("Mer"),
      4: t("Gio"),
      5: t("Ven"),
      6: t("Sab"),
    };
    // console.log(dataTime);
    let date = new Date(dataTime);
    // var texts = [days[date.getDay()], date.getDate(), date.getHours(), date.getMinutes()];
    let texts =
      days[date.getDay()] +
      " " +
      date.getDate() +
      " " +
      date.getHours() +
      ":" +
      ("0" + date.getMinutes()).slice(-2);
    return texts;
  }

  // convertFullDate(dataTime) {
  //   const {t} = this.props;
  //   let days = {
  //     0: t("Dom"),
  //     1: t("Lun"),
  //     2: t("Mar"),
  //     3: t("Mer"),
  //     4: t("Gio"),
  //     5: t("Ven"),
  //     6: t("Sab"),
  //   };
  //   // console.log(dataTime);
  //   let date = new Date(dataTime);
  //   // var texts = [days[date.getDay()], date.getDate(), date.getHours(), date.getMinutes()];
  //   let texts =
  //     // days[date.getDay()] +
  //     // " " +
  //     date.getDate() +
  //     "/" +
  //     String(date.getMonth() + 1) +
  //     "/" +
  //     date.getFullYear() +
  //     " " +
  //     date.getHours() +
  //     ":" +
  //     ("0" + date.getMinutes()).slice(-2);
  //   return texts;
  // }

  getOption(
    timeX,
    quotaMisurata,
    quotaPrevista,
    min,
    grado1arr,
    grado2arr,
    grado3arr,
    grado4_5arr,
    quotaMaxPrevista
  ) {
    const {t} = this.props;
    // console.log(quotaMisurata);
    // console.log(timeX[timeX.length - 1]);
    let alarm = {
      grado1: 194.5,
      grado2: 194.85,
      grado3: 195.5,
      grado4_5: 195.75,
    };
    return {
      title: {
        text: "Previsione",
        show: false,
      },

      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
        extraCssText: "background-color: transparent;",
        // position: function(pos, params, dom, rect, size) {
        //   // tooltip will be fixed on the right if mouse hovering on the left,
        //   // and on the left if hovering on the right.
        //   console.log(pos);
        //   var obj = {top: 0};

        //   obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 5;
        //   console.log (obj);
        //   return obj;
        // },
        formatter: (params, ticket, callback) => {
          // console.log(params[1].marker);
          let newDate = this.convertDate(params[0].name);
          // console.log(params[0].data);
          return (
            "<div class='tooltip'><p>" +
            newDate +
            "</p><table><tr><td>" +
            params[0].marker +
            " " +
            params[0].seriesName +
            ":</td><td>" +
            (params[0].data === ""
              ? "N/A"
              : (Math.round(params[0].data * 100) / 100).toFixed(2)) +
            " m" +
            "</td></tr><tr><td>" +
            params[1].marker +
            " " +
            params[1].seriesName +
            ":</td><td>" +
            (params[1].data === ""
              ? "N/A"
              : (Math.round(params[1].data * 100) / 100).toFixed(2)) +
            " m" +
            "</td></tr><tr><td>" +
            '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:green;"></span>' +
            t("Grado 1") +
            "</td><td>" +
            alarm.grado1 +
            " m" +
            "</td></tr><tr><td>" +
            '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#d7dd06;"></span>' +
            t("Grado 2") +
            "</td><td>" +
            alarm.grado2 +
            " m" +
            "</td></tr><tr><td>" +
            '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:orange;"></span>' +
            t("Grado 3") +
            "</td><td>" +
            alarm.grado3 +
            " m" +
            "</td></tr><tr><td>" +
            '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:red;"></span>' +
            t("Grado 4/5") +
            "</td><td>" +
            alarm.grado4_5 +
            " m" +
            "</td></tr><div>"
          );
        },
      },
      dataZoom: [
        {
          show: true,
          realtime: true,
          start: 70,
          end: 100,
          xAxisIndex: 0,
          filterMode: "empty",
          labelFormatter: function(value, valueStr) {
            return "";
          },
        },
        {
          type: "inside",
          realtime: true,
          start: 70,
          end: 100,
          xAxisIndex: 0,
        },
      ],
      grid: {
        containLabel: true,
        left: 13,
        top: 30,
        right: 15,
        bottom: 24,
      },
      legend: {
        type: "scroll",
        padding: [
          5, // up
          5, // right
          5, // down
          5, // left
        ],
        data: [
          {
            name: t("Valori misurati"),
            // compulsorily set icon as a circle
            // set up the text in red
            icon: "circle",
            textStyle: {
              color: "black",
            },
          },
          {
            name: t("Altezza prevista"),
            // compulsorily set icon as a circle
            // set up the text in red
            icon: "circle",
            textStyle: {
              color: "black",
            },
          },
          {
            name: t("Grado 1"),
            // compulsorily set icon as a circle
            // set up the text in red
            icon: "circle",
            textStyle: {
              color: "black",
            },
          },
          {
            name: t("Grado 2"),
            icon: "circle",
            // compulsorily set icon as a circle
            // set up the text in red
            textStyle: {
              color: "black",
            },
          },
          {
            name: t("Grado 3"),
            icon: "circle",
            // compulsorily set icon as a circle
            // set up the text in red
            textStyle: {
              color: "black",
            },
          },
          {
            name: t("Grado 4/5"),
            icon: "circle",
            // compulsorily set icon as a circle
            // set up the text in red
            textStyle: {
              color: "black",
            },
          },
          {
            name: t("Quota massima prevista"),
            // compulsorily set icon as a circle
            // icon: 'image://home/davide/workspace/sitgap_home/public/assets/img/triangleChart.png',
            // icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAABHNCSVQICAgIfAhkiAAAABl0RVh0U29mdHdhcmUAZ25vbWUtc2NyZWVuc2hvdO8Dvz4AAAD0SURBVEiJtZbNDYJAEEZnkfPWoB1oA1qBGL1KYguEikxsRBvQErQNg/p5ANbF7M8sC5OQbMhk3veGwyIAgEasZMzhRERpe7g/hhWZTUV9QFPZtgKlz0Ge/FC1Y6FWVBbDbassJuqspq6WCWXreEi+T2gxF78X0Op8eUev53r76CPRAcR+C333VkCMxX96I6CvhSm9FdDHwpTeCgi1sKV3AkIsbOmdAK6FK70XwLFwpfcCfBa+9CyAy8KXngWwWXDSswEmC056NgDoWnDTBwF0C256ABAA/9Lf7F4kJdHpmPqbm+J3Un3rSSn8jVoFGfSp0X9bvrgD0O05IqWlAAAAAElFTkSuQmCC',
            icon:
              "path://m 10.851958,20.655464 -5.5151148,-9.552459 -5.51511505,-9.5524576 11.03022885,2e-7 11.030229,-8e-7 -5.515115,9.5524592 z",
            // set up the text in red
            textStyle: {
              color: "black",
            },
          },
        ],
        // "Quota lago misurata", "Quota lago prevista"],
      },
      xAxis: {
        name: t("Tempo"),
        nameLocation: "middle",
        nameGap: "20",
        data: timeX,
        axisPointer: {
          label: {
            show: false,
          },
        },
        axisLabel: {
          // formatter: function(timeX, index) {
          //   // Formatted to be month/day; display year only in the first label
          //   var date = new Date(timeX);
          //   // var texts = [days[date.getDay()], date.getDate(), date.getHours(), date.getMinutes()];
          //   var texts =
          //     days[date.getDay()] +
          //     " " +
          //     date.getDate() +
          //     " " +
          //     date.getHours() +
          //     ":" +
          //     ("0" + date.getMinutes()).slice(-2);
          //   // texts.join(" ")
          //   // if (idx === 0) {
          //   //     texts.unshift(date.getYear());
          //   // }
          //   //return texts.join("/");
          //   return texts;
          // },
          formatter: timeX => this.convertDate(timeX),
        },
      },
      yAxis: {
        name: t("Altezza"),
        nameRotate: "90",
        nameLocation: "middle",
        nameGap: "60",
        min: Math.floor(min * 100) / 100 - 0.5,
        max: 195.75,
        axisPointer: {
          label: {
            show: false,
          },
        },
        axisLabel: {
          formatter: "{value} m",
        },
      },
      series: [
        {
          //   tooltip: {
          //     trigger: "item",
          //     axisPointer: {
          //       type: "cross",
          //     },
          //     formatter: d => {
          //       console.log(d);
          //       // de
          //       let newDate = this.convertData(d.name);
          //       // console.log(newDate);
          //       return d.seriesName + " " + d.marker + ":  " + newDate +", "+ d.data ;

          //     },
          //   },
          itemStyle: {normal: {color: "black"}},
          name: t("Valori misurati"),
          type: "line",
          data: quotaMisurata,
          lineStyle: {color: "black"},
        },
        {
          itemStyle: {
            normal: {
              color: "#007bff",
              // "#0000ff"
            },
          },
          name: t("Altezza prevista"),
          type: "line",
          areaStyle: {color: "#88a9e5"},
          // data: [30, 20, 36, 10, 10, 20],
          data: quotaPrevista,
          lineStyle: {color: "#007bff"},
        },
        {
          itemStyle: {normal: {color: "green"}},
          name: t("Grado 1"),
          type: "line",
          // data: [30, 20, 36, 10, 10, 20],
          data: grado1arr,
          hoverAnimation: false,
          cursor: "none",
          showSymbol: false,
          lineStyle: {
            color: "green",
            type: "dashed",
          },
        },
        {
          itemStyle: {normal: {color: "#d7dd06"}},
          name: t("Grado 2"),
          type: "line",
          // data: [30, 20, 36, 10, 10, 20],
          data: grado2arr,
          hoverAnimation: false,
          cursor: "none",
          showSymbol: false,
          lineStyle: {
            color: "#d7dd06",
            type: "dashed",
          },
        },
        {
          itemStyle: {normal: {color: "orange"}},
          name: t("Grado 3"),
          type: "line",
          // data: [30, 20, 36, 10, 10, 20],
          data: grado3arr,
          hoverAnimation: false,
          cursor: "none",
          showSymbol: false,
          lineStyle: {
            color: "orange",
            type: "dashed",
          },
        },
        {
          itemStyle: {normal: {color: "red"}},
          name: t("Grado 4/5"),
          type: "line",
          // data: [30, 20, 36, 10, 10, 20],
          data: grado4_5arr,
          hoverAnimation: false,
          cursor: "none",
          showSymbol: false,
          lineStyle: {
            color: "red",
            type: "dashed",
          },
        },
        // {
        //   itemStyle: {normal: {color: "#0000ff"}},
        //   name: t("Quota massima prevista"),
        //   type: "line",
        //   hoverAnimation: false,
        //   cursor: "none",
        //   showSymbol: false,
        //   markLine: {
        //     symbol: "none",
        //     lineStyle: {
        //       color: "#0000ff",
        //       type: "solid",
        //       width: "2",
        //     },
        //     data: [
        //       {
        //         name: t("Quota massima prevista"),
        //         xAxis: quotaMaxPrevista.time,
        //         label: {
        //           normal: {
        //             show: true,
        //             position: "middle",
        //             formatter: (params) => { return (
        //               t("Quota massima prevista")
        //                 )},
        //             color: "black",
        //           },
        //         },
        //       },
        //     ],
        //   },
        // },
        {
          itemStyle: {normal: {color: "#0000ff"}},
          name: t("Quota massima prevista"),
          type: "line",
          hoverAnimation: false,
          cursor: "none",
          showSymbol: true,
          markPoint: {
            symbol: "triangle",
            symbolRotate: 180,
            symbolOffset: [0, "-50%"],
            symbolSize: 20,
            itemStyle: {
              color: "#0000ff",
            },
            data: [
              {
                name: t("Quota massima prevista"),
                value: Math.round(quotaMaxPrevista.value * 100) / 100,
                xAxis: quotaMaxPrevista.time,
                yAxis: quotaMaxPrevista.value,
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: params => {
                      return (Math.round(quotaMaxPrevista.value * 100) / 100).toFixed(2);
                    },
                    color: "black",
                    backgroundColor: "white",
                  },
                },
              },
            ],
          },
        },

        // {
        //   name: t("Quota massima prevista"),
        //   itemStyle: {normal: {color: "blue"}},
        //   type: "line",
        //   markLine: {
        //     silent: true,
        //     data: [
        //       // 1st line we want to draw
        //       [
        //         // start point of the line
        //         // we have to defined line attributes only here (not in the end point)
        //         {
        //           xAxis: timeX[20],
        //           // yAxis: alarm.grado3,
        //           symbol: "none",
        //           lineStyle: {
        //             normal: {
        //               color: "orange",
        //               width: 2,
        //             },
        //           },
        //           label: {
        //             normal: {
        //               show: true,
        //               position: "end",
        //               formatter: t("Quota massima prevista"),
        //               color: "black",
        //             },
        //           },
        //         },
        //         end point of the line
        //         {
        //           xAxis: timeX[timeX.length - 1],
        //           yAxis: alarm.grado3,
        //           symbol: "none",
        //         },
        //       ],
        //     ],
        //   },
        // },

        // {
        //   name: t("Grado 4/5"),
        //   itemStyle: {normal: {color: "red"}},
        //   type: "line",
        //   markLine: {
        //     silent: true,
        //     data: [
        //       // 1st line we want to draw
        //       [
        //         // start point of the line
        //         // we have to defined line attributes only here (not in the end point)
        //         {
        //           xAxis: timeX[0],
        //           yAxis: alarm.grado1,
        //           symbol: "none",
        //           lineStyle: {
        //             normal: {
        //               color: "green",
        //               width: 2,
        //             },
        //           },
        //           label: {
        //             normal: {
        //               show: false,
        //               position: "end",
        //               formatter: t("Grado 1"),
        //               color: "black",
        //             },
        //           },
        //         },
        //         // end point of the line
        //         {
        //           xAxis: timeX[timeX.length - 1],
        //           yAxis: alarm.grado1,
        //           symbol: "none",
        //         },
        //       ],
        //     ],
        //   },
        // },
        // {
        //   name: t("Grado 2"),
        //   itemStyle: {normal: {color: "#d7dd06"}},
        //   type: "line",
        //   markLine: {
        //     silent: true,
        //     data: [
        //       // 1st line we want to draw
        //       [
        //         // start point of the line
        //         // we have to defined line attributes only here (not in the end point)
        //         {
        //           xAxis: timeX[0],
        //           yAxis: alarm.grado2,
        //           symbol: "none",
        //           lineStyle: {
        //             normal: {
        //               width: 2,
        //               color: "#d7dd06",
        //             },
        //           },
        //           label: {
        //             normal: {
        //               show: false,
        //               position: "end",
        //               formatter: t("Grado 2"),
        //               color: "black",
        //             },
        //           },
        //         },
        //         // end point of the line
        //         {
        //           xAxis: timeX[timeX.length - 1],
        //           yAxis: alarm.grado2,
        //           symbol: "none",
        //         },
        //       ],
        //     ],
        //   },
        // },
        // {
        //   name: t("Grado 3"),
        //   itemStyle: {normal: {color: "orange"}},
        //   type: "line",
        //   markLine: {
        //     silent: true,
        //     data: [
        //       // 1st line we want to draw
        //       [
        //         // start point of the line
        //         // we have to defined line attributes only here (not in the end point)
        //         {
        //           xAxis: timeX[0],
        //           yAxis: alarm.grado3,
        //           symbol: "none",
        //           lineStyle: {
        //             normal: {
        //               color: "orange",
        //               width: 2,
        //             },
        //           },
        //           label: {
        //             normal: {
        //               show: false,
        //               position: "end",
        //               formatter: t("Grado 3"),
        //               color: "black",
        //             },
        //           },
        //         },
        //         // end point of the line
        //         {
        //           xAxis: timeX[timeX.length - 1],
        //           yAxis: alarm.grado3,
        //           symbol: "none",
        //         },
        //       ],
        //     ],
        //   },
        // },
        // {
        //   name: t("Grado 4/5"),
        //   itemStyle: {normal: {color: "red"}},
        //   type: "line",
        //   animation: true,
        //   markLine: {
        //     silent: true,
        //     data: [
        //       // 1st line we want to draw
        //       [
        //         // start point of the line
        //         // we have to defined line attributes only here (not in the end point)
        //         {
        //           xAxis: timeX[0],
        //           yAxis: alarm.grado4_5,
        //           symbol: "none",
        //           lineStyle: {
        //             normal: {
        //               color: "red",
        //               width: 2,
        //             },
        //           },
        //           label: {
        //             normal: {
        //               show: false,
        //               position: "end",
        //               formatter: t("Grado 4/5"),
        //               color: "black",
        //             },
        //           },
        //         },
        //         // end point of the line
        //         {
        //           xAxis: timeX[timeX.length - 1],
        //           yAxis: alarm.grado4_5,
        //           symbol: "none",
        //         },
        //       ],
        //     ],
        //   },
        // },
      ],
    };
  }

  render() {
    // this.myChart.on('dataZoom', function() {
    //   var option = myChart.getOption();
    //   console.log(option.dataZoom[0].startValue, option.dataZoom[0].endValue);
    // });
    return (
      <Suspense fallback="loading">
        <div id="chart1"></div>
      </Suspense>
    );
  }
}

export default Chart;
