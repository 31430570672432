import {connect} from "react-redux";
import NavBar from "../components/NavBar/NavBar";
import {hideMobileNavigationMenu} from "../actions/BurgerAction";
import {changeLang} from "../actions/LanguageAction";
import {withTranslation} from "react-i18next";

const mapStateToProps = (state, ownProps) => {
  return {
    visibleMobileMenu: state.BurgerMenuReducer.visibleMobileMenu,
    language: state.LanguageReducer.language,
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onHideMobileNavigationMenu: () => dispatch(hideMobileNavigationMenu()),
    changeLang: lang => dispatch(changeLang(lang)),
  };
};

const NavBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavBar);

export default withTranslation()(NavBarContainer);
