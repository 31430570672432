import React, {Component} from "react";
import L from "leaflet";
import "../../../node_modules/leaflet/dist/leaflet.css";
import "./Map.scss";

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // mapId: props.mapId,
      // mapName: props.mapName,
      // layerAdded: false,
      // layer1:{},
      // layer2:{},
      mymap: {},
      myLayer: {},
      layerAdded: false,
    };
  }

  componentDidUpdate() {
    const {esondazione, isLoading} = this.props;
    if (!isLoading) {
      // console.log(esondazione);

      //   var states = [{
      //     "type": "Feature",
      //     "properties": {"party": "Republican"},
      //     "geometry": {
      //         "type": "Polygon",
      //         "coordinates": [[
      //             [-104.05, 48.99],
      //             [-97.22,  48.98],
      //             [-96.58,  45.94],
      //             [-104.03, 45.94],
      //             [-104.05, 48.99]
      //         ]]
      //     }
      // }, {
      //     "type": "Feature",
      //     "properties": {"party": "Democrat"},
      //     "geometry": {
      //         "type": "Polygon",
      //         "coordinates": [[
      //             [-109.05, 41.00],
      //             [-102.06, 40.99],
      //             [-102.03, 36.99],
      //             [-109.04, 36.99],
      //             [-109.05, 41.00]
      //         ]]
      //     }
      // }];

      // L.geoJSON(states, {
      //     style: function(feature) {
      //         switch (feature.properties.party) {
      //             case 'Republican': return {color: "#ff0000"};
      //             case 'Democrat':   return {color: "#0000ff"};
      //         }
      //     }
      // }).addTo(this.state.mymap);
      if (!this.state.layerAdded) {
        var myLayer = L.geoJSON(esondazione, {
          weight: 2,
          style: function(feature) {
            return {color: "#0000ff"};
          },
        }).addTo(this.state.mymap);

        this.setState({layerAdded: true, myLayer: myLayer});
      }

      // this.state.myLayer.addData(esondazione);
    }
  }

  componentDidMount() {
    const {mapName, fetchEsondazione} = this.props;
    // console.log(mapName);
    fetchEsondazione();
    var mymap = L.map(mapName).setView([46.1565, 8.8196], 13);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      // minZoom: 9,
      // maxZoom: 18,
    }).addTo(mymap);

    this.setState({
      mymap: mymap,
      // myLayer: myLayer
    });
  }

  render() {
    const {mapName} = this.props;
    return <div id={mapName}> </div>;
  }
}

export default Map;
