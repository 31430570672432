import {
  ADD_QUOTA_LAGO,
  LOADING_QUOTA_LAGO,
  FAILED_QUOTA_LAGO,
} from "../actions/QuotaLagoAction";

const DEFAULT_STATE = {
  isLoading: true,
  errMess: null,
  quotaLago: {},
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ADD_QUOTA_LAGO:
      return {...state, isLoading: false, errMess: null, quotaLago: action.payload};
    case LOADING_QUOTA_LAGO:
      return {...state, isLoading: true, errMess: null, quotaLago: {}};
    case FAILED_QUOTA_LAGO:
      return {...state, isLoading: false, errMess: action.payload};
    default:
      return state;
  }
};

// import {
//   ADD_QUOTA_LAGO,
//   LOADING_QUOTA_LAGO,
//   FAILED_QUOTA_LAGO,
// } from "../actions/QuotaLagoAction";

// export const QuotaLago = (state = {
//   isLoading: true,
//   errMess: null,
//   quotaLago: {}
// }, action) => {
//   switch (action.type) {
//     case ADD_QUOTA_LAGO:
//       return {...state,  isLoading: false, errMess: null, quotaLago: action.payload };
//     case LOADING_QUOTA_LAGO:
//       return {...state,  isLoading: true, errMess: null, quotaLago: {} };
//     case FAILED_QUOTA_LAGO:
//       return {...state,  isLoading: false, errMess: action.payload };
//     default:
//       return state;
//   }
// };
