// import React from "react";
// import ReactDOM from "react-dom";
// import "./index.css";
// import App from "./App";
// import * as serviceWorker from "./serviceWorker";
// import "bootstrap/dist/css/bootstrap.min.css";

// ReactDOM.render(<App />, document.getElementById("root"));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

import {render} from "react-dom";
import Router from "./routes";
import store from "./store";
import * as serviceWorker from "./serviceWorker";

// import "bootstrap/dist/css/bootstrap.min.css";

const run = () => {
  render(Router(store), document.getElementById("rootContainer"));
};

if (
  ["complete", "loaded", "interactive"].includes(document.readyState) &&
  document.body
) {
  run();
} else {
  document.addEventListener("DOMContentLoaded", run, false);
}

serviceWorker.unregister();
