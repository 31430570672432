import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Main from "../components/Main/MainComponent";
import {changeScreenSize} from "../actions/ScreenAction";

const MainContainer = props => {
  return <Main {...props} />;
};
const mapStateToProps = (state, ownProps) => {
  return {
    language: state.LanguageReducer.language,
    width: state.ScreenReducer.width,
    height: state.ScreenReducer.height,
    view: state.ViewReducer.view,
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => ({
  changeScreenSize: (width, height) => {
    dispatch(changeScreenSize(width, height));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(MainContainer));
