import React, {Component, Suspense} from "react";
import {
  // Button,
  // Divider,
  // Dropdown,
  // Grid,
  // Header,
  // Icon,
  // Image,
  // Label,
  // Menu,
  // Message,
  // Form,
  // Segment,
  // Dimmer,
  Loader,
  Table,
} from "semantic-ui-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./Details.scss";

const TabDetail = props => {
  const {t, quotaLago, convertFullDate} = props;
  let allarme = {
    0: t("Nessuno"),
    1: t("Grado 1"),
    2: t("Grado 2"),
    3: t("Grado 3"),
    4: t("Grado 4/5"),
  };

  let alarmColor = {
    0: "white",
    1: "green",
    2: "#d7dd06",
    3: "orange",
    4: "red",
  };

  return (
    <Table className="table-details">
      {/* <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <h2>{t("Statistiche")}</h2>
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header> */}
      <Table.Body>
        <Table.Row>
          <Table.Cell>{t("Quota attuale")}</Table.Cell>
          <Table.Cell>
            {(Math.round(quotaLago.actual_quote * 100) / 100).toFixed(2)} m <br />
            {convertFullDate(t, quotaLago.model_run)}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>{t("Andamento")}</Table.Cell>
          <Table.Cell>
            {quotaLago.actual_quote >= quotaLago.max_fore_level ? (
              <FontAwesomeIcon icon="long-arrow-alt-up" transform={{rotate: 132}} />
            ) : (
              <FontAwesomeIcon icon="long-arrow-alt-up" transform={{rotate: 42}} />
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>{t("Quota massima prevista")}</Table.Cell>
          <Table.Cell>
            {quotaLago.actual_quote >= quotaLago.max_fore_level
              ? (Math.round(quotaLago.actual_quote * 100) / 100).toFixed(2)
              : (Math.round(quotaLago.max_fore_level * 100) / 100).toFixed(2)}
            {" m"} <br />
            {quotaLago.actual_quote >= quotaLago.max_fore_level
              ? convertFullDate(t, quotaLago.model_run)
              : convertFullDate(t, quotaLago.time_max_fore_level)}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>{t("Allarme")}</Table.Cell>
          <Table.Cell>
            {quotaLago.fore_alarm_level === 0 ? (
              ""
            ) : (
              <span
                style={{
                  display: "inline-block",
                  marginRight: "5px",
                  borderRadius: "10px",
                  width: "10px",
                  height: "10px",
                  backgroundColor: alarmColor[quotaLago.fore_alarm_level],
                }}
              ></span>
            )}
            {allarme[quotaLago.fore_alarm_level]}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

const Loading = () => {
  return (
    <Loader active inline="centered" size="small">
      Loading
    </Loader>
  );
};

class Details extends Component {
  // constructor(props) {
  //   super(props);
  // }

  convertFullDate(t, dataTime) {
    // let days = {
    //   0: t("Dom"),
    //   1: t("Lun"),
    //   2: t("Mar"),
    //   3: t("Mer"),
    //   4: t("Gio"),
    //   5: t("Ven"),
    //   6: t("Sab"),
    // };
    // console.log(dataTime);
    let date = new Date(dataTime);
    // var texts = [days[date.getDay()], date.getDate(), date.getHours(), date.getMinutes()];
    let texts =
      // days[date.getDay()] +
      // " " +
      date.getDate() +
      "/" +
      String(date.getMonth() + 1) +
      "/" +
      date.getFullYear() +
      " " +
      date.getHours() +
      ":" +
      ("0" + date.getMinutes()).slice(-2);
    return texts;
  }

  render() {
    const {language, quotaLago, isLoading} = this.props;
    const {t} = this.props;
    // console.log(language);
    return (
      <div className="details">
        {isLoading ? (
          <Loading />
        ) : (
          <Suspense fallback="loading">
            <TabDetail
              quotaLago={quotaLago}
              language={language}
              t={t}
              convertFullDate={this.convertFullDate}
            />
          </Suspense>
        )}
      </div>
    );
  }
}

export default Details;
