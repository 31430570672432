import {CHANGE_SCREEN_SIZE} from "../actions/ScreenAction";

const DEFAULT_STATE = {
  width: 1920,
  height: 1080,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CHANGE_SCREEN_SIZE:
      return {...state, width: action.width, height: action.height};
    default:
      return state;
  }
};
