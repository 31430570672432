import {
  ADD_ESONDAZIONE,
  LOADING_ESONDAZIONE,
  FAILED_ESONDAZIONE,
} from "../actions/EsondazioneAction";

const DEFAULT_STATE = {
  isLoading: true,
  errMess: null,
  esondazione: {},
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ADD_ESONDAZIONE:
      return {...state, isLoading: false, errMess: null, esondazione: action.payload};
    case LOADING_ESONDAZIONE:
      return {...state, isLoading: true, errMess: null, esondazione: {}};
    case FAILED_ESONDAZIONE:
      return {...state, isLoading: false, errMess: action.payload};
    default:
      return state;
  }
};
