import {connect} from "react-redux";
import {
  hideMobileNavigationMenu,
  showMobileNavigationMenu,
} from "../actions/BurgerAction";
import {changeView} from "../actions/ViewAction";
import HamburgerIcon from "../components/HamburgerIcon/HamburgerIcon";

const mapStateToProps = (state, ownProps) => {
  return {
    view: state.ViewReducer.view,
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onHideMobileNavigationMenu: () => dispatch(hideMobileNavigationMenu()),
    onShowMobileNavigationMenu: () => dispatch(showMobileNavigationMenu()),
    changeView: view => dispatch(changeView(view)),
  };
};

const HamburgerIconContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HamburgerIcon);

export default HamburgerIconContainer;
