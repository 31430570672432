import React from "react";
import {connect} from "react-redux";
import {fetchQuotaLago} from "../actions/QuotaLagoAction";

import Chart from "../components/Chart/ChartComponent";
import {withTranslation} from "react-i18next";

const ChartContainer = props => {
  return <Chart {...props} />;
};
const mapStateToProps = (state, ownProps) => {
  return {
    quotaLago: state.QuotaLagoReducer.quotaLago,
    isLoading: state.QuotaLagoReducer.isLoading,
    language: state.LanguageReducer.language,

    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchQuotaLago: () => {
    dispatch(fetchQuotaLago());
  },
});

// const mapDispatchToProps = dispatch => {
//   return {
//     fetchQuotaLago: () => dispatch(fetchQuotaLago()),
//   };
// };

// const ChartContainer = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ChartContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ChartContainer));
