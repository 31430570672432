import React, {Suspense} from "react";
import "semantic-ui-css/semantic.min.css";
import HamburgerIconContainer from "./containers/HamburgerIconContainer.js";
import NavBarContainer from "./containers/NavBarContainer.js";
import {Route} from "react-router-dom";
import "./DefaultLayout.scss";
import {library} from "@fortawesome/fontawesome-svg-core";
import {
  faLongArrowAltUp,
  faEnvelope,
  faKey,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import {I18nextProvider} from "react-i18next";
import i18n from "./config/i18n";

library.add(faLongArrowAltUp, faEnvelope, faKey, faUnlockAlt);

const DefaultLayout = ({component: Component, ...rest}) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <I18nextProvider i18n={i18n}>
          <Suspense fallback="loading">
            <div className="default-layout">
              <HamburgerIconContainer showSideBarMenu />

              <NavBarContainer />

              <div className="main-container">
                <Component {...matchProps} />
              </div>
              {/* <div className="layout-footer">Footer</div> */}
            </div>
          </Suspense>
        </I18nextProvider>
      )}
    />
  );
};

export default DefaultLayout;
