// import React, { Component } from "react";
// import { connect } from "react-redux";
// import '../styles/styles.css';
// // import { Button } from 'react-bootstrap';

// const mapStateToProps = state => {
//   return {
//     //piogge: state.piogge,
//   };
// };

// const mapDispatchToProps = dispatch => ({
//   // fetchAll: () => { dispatch(fetchAll()) },
// });

// class Main extends Component {
//   constructor(props) {
//     super(props);
//     this.state = { isOpen: false };
//   }

//   componentDidMount() {
//     // this.props.fetchAll();
//   }

//   toggleMenu() {
//     this.setState({ isOpen: !this.state.isOpen });
//   }

//   render() {
//     return (
//       <div className="wrapper">
//       </div>
//     );
//   }
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(Main);

import React, {Component, Suspense} from "react";
import MapContainer from "../../containers/MapContainer";
import ChartContainer from "../../containers/ChartContainer";
import DetailsContainer from "../../containers/DetailsContainer";
import "./Main.scss";
// import {Tab} from "semantic-ui-react";

class Main extends Component {
  constructor(props) {
    super(props);
    // this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    // this.setState({ width: window.innerWidth, height: window.innerHeight });
    const {changeScreenSize} = this.props;
    changeScreenSize(window.innerWidth, window.innerHeight);
  }

  // createPanes = () => {
  //   let styleBorderPanes = {width:"100%", padding: "0rem 0rem 0rem 0rem"};
  //   styleBorderPanes = {
  //     width:"100%",
  //     padding: "0rem 0rem 0rem 0rem",
  //     borderRight: "none",
  //     boxShadow: "none",
  //   };

  //   return [
  //     {
  //       menuItem: {key: "mappa", icon: "globe", content: "Mappa"},
  //       // 'Mappa',
  //       render: () => (
  //         <Tab.Pane style={styleBorderPanes} className="tabPane">
  //           <MapContainer mapName="mapMobile"/>
  //         </Tab.Pane>
  //       ),
  //     },
  //     {
  //       menuItem: {key: "details", icon: "list", content: "Details"},
  //       // 'Stazioni',
  //       render: () => (
  //         <Tab.Pane style={styleBorderPanes} className="tabPane">
  //           <DetailsContainer />
  //         </Tab.Pane>
  //       ),
  //     },
  //   ];
  // };

  render() {
    // console.log(this.state.width);
    const {t, view, 
      // width, height
    } = this.props;
    // console.log(width);
    return (
      <Suspense fallback="loading">
        <div className="main-wrapper">
          {/* <h1>Main Page</h1> */}

          {/* DESKTOP */}
          <div className="first-row-desktop">
            <div style={{height: "5%"}}>
              <h3>{t("Quota massima prevista")}:</h3>
            </div>
            <MapContainer mapName="mapDesk" />
          </div>

          {/* MOBILE */}
          <div className="first-row-mobile">
            {view === "map" ? (
              <MapContainer mapName="mapMobile" />
            ) : (
              <div className="table-container">
                <DetailsContainer />
              </div>
            )}
            {/* <div style={{height: "6%"}}>
              <h3>{t("Statistiche")}:</h3>
            </div>
            <div className="table-container">
              <DetailsContainer />
            </div> */}

            {/* <Tab className="Tab"
              panes={this.createPanes()}
            /> */}
          </div>

          {/* ALL SCREEN */}
          <div className="second-row">
            <div className="chart-container">
              <div style={{height: "6%"}}>
                <h3>{t("Grafico")}:</h3>
              </div>
              <ChartContainer />
            </div>

            <div className="details-container">
              <div style={{height: "8%", paddingLeft: "5%"}}>
                <h3>{t("Statistiche")}:</h3>
              </div>
              <div className="table-container">
                <DetailsContainer />
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    );
  }
}

export default Main;
