import React, {Component} from "react";
import "./NavBar.scss";
// import NavBarItem from "./NavBarItem";
import {imgUrl} from "../../shared/baseUrl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {sitgapUrl} from "../../shared/baseUrl";
import {
  Button,
  // Divider,
  // Dropdown,
  // Grid,
  // Header,
  // Icon,
  Image,
  // Label,
  // Menu,
  // Message,
  // Form,
  // Segment,
  // Table
} from "semantic-ui-react";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.handleMouseDown = this.handleMouseDown.bind(this);
  }

  onXClick(lang) {
    this.props.changeLang(lang);
    this.props.i18n.changeLanguage(lang);
  }

  handleMouseDown(e) {
    this.props.onHideMobileNavigationMenu();
    e.stopPropagation();
  }

  render() {
    let {
      t, language, visibleMobileMenu, 
      //onHideMobileNavigationMenu
      } = this.props;
    // const {t} = this.props;
    const navBarClass = visibleMobileMenu ? "show-menu" : "hide-menu";
    return (
      <div className="bar-wrapper">
        <div
          onMouseDown={this.handleMouseDown}
          className={`overlay-container ${navBarClass}`}
        ></div>
        <div className={`sidebar-container ${navBarClass}`}>
          <div className="rowNav">
            <div className="sidebar">
              <div className="logoNav">
                <Image
                  src={imgUrl + "pci_banner_small.gif"}
                  size="small"
                  alt="Logo protezione civile"
                  className="imageLogo"
                />
              </div>

              {/* <NavBarItem
                path="/about"
                onHideMobileNavigationMenu={onHideMobileNavigationMenu}
                linkText="About us"
              />

              <NavBarItem
                path="/contact"
                onHideMobileNavigationMenu={onHideMobileNavigationMenu}
                linkText="Contact us"
              />  */}

              {/* <li className='btn-group-list'> */}
              {/* <div className="btn-group">
                <button>IT</button>
                <button>EN</button>
                <button>DE</button>
              </div> */}

              <div className="reserved-area">
                <a style={{textDecoration: "underline"}} href={sitgapUrl}>
                  <FontAwesomeIcon icon="unlock-alt" style={{marginRight: "0.5em"}} />
                  {t("Area riservata")}
                </a>
              </div>

              <div className="btn-group">
                <Button.Group size="small">
                  <Button
                    className={language === "it" ? "active" : ""}
                    onClick={() => {
                      this.onXClick("it");
                    }}
                  >
                    IT
                  </Button>
                  <Button
                    className={language === "de" ? "active" : ""}
                    onClick={() => {
                      this.onXClick("de");
                    }}
                  >
                    DE
                  </Button>
                  <Button
                    className={language === "fr" ? "active" : ""}
                    onClick={() => {
                      this.onXClick("fr");
                    }}
                  >
                    FR
                  </Button>
                  <Button
                    className={language === "en" ? "active" : ""}
                    onClick={() => {
                      this.onXClick("en");
                    }}
                  >
                    EN
                  </Button>
                </Button.Group>
              </div>

              {/* </li> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NavBar;
