import {esondazioneUrl} from "../shared/baseUrl";

export const ADD_ESONDAZIONE = "ADD_ESONDAZIONE";
export const LOADING_ESONDAZIONE = "LOADING_ESONDAZIONE";
export const FAILED_ESONDAZIONE = "FAILED_ESONDAZIONE";

export const fetchEsondazione = () => dispatch => {
  dispatch(loadingEsondazione(true));

  // return fetch(rainAnnuario_url)
  return fetch(esondazioneUrl + "quotaPoligon.geojson")
    .then(
      response => {
        if (response.ok) {
          // console.log(response);
          return response;
        } else {
          var error = new Error("Error" + response.status + ": " + response.statusText);
          error.response = response;
          throw error;
        }
      },
      error => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then(response => response.json())
    .then(esondazione => dispatch(addEsondazione(esondazione)))
    .catch(error => dispatch(failedEsondazione(error.message)));
};

export const loadingEsondazione = () => ({
  type: LOADING_ESONDAZIONE,
});

export const failedEsondazione = errmess => ({
  type: FAILED_ESONDAZIONE,
  payload: errmess,
});

export const addEsondazione = esondazione => ({
  type: ADD_ESONDAZIONE,
  payload: esondazione,
});
