import {combineReducers} from "redux";
import BurgerMenuReducer from "./BurgerMenuReducer";
import QuotaLagoReducer from "./QuotaLagoReducer";
import EsondazioneReducer from "./EsondazioneReducer";
import LanguageReducer from "./LanguageReducer";
import ScreenReducer from "./ScreenReducer";
import ViewReducer from "./ViewReducer";

export default combineReducers({
  BurgerMenuReducer,
  QuotaLagoReducer,
  EsondazioneReducer,
  LanguageReducer,
  ScreenReducer,
  ViewReducer,
});
