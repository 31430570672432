import {CHANGE_VIEW} from "../actions/ViewAction";

const DEFAULT_STATE = {
  // details or map
  view: "details",
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CHANGE_VIEW:
      return {...state, view: action.view};
    default:
      return state;
  }
};
