import React from "react";
import {connect} from "react-redux";
import {fetchEsondazione} from "../actions/EsondazioneAction";

import Map from "../components/Map/MapComponent";

const MapContainer = props => {
  return <Map {...props} />;
};
const mapStateToProps = (state, ownProps) => {
  return {
    esondazione: state.EsondazioneReducer.esondazione,
    isLoading: state.EsondazioneReducer.isLoading,
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchEsondazione: () => {
    dispatch(fetchEsondazione());
  },
});

// const mapDispatchToProps = dispatch => {
//   return {
//     fetchQuotaLago: () => dispatch(fetchQuotaLago()),
//   };
// };

// const ChartContainer = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ChartContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapContainer);
