import {quotaLagoUrl} from "../shared/baseUrl";

export const ADD_QUOTA_LAGO = "ADD_QUOTA_LAGO";
export const LOADING_QUOTA_LAGO = "LOADING_QUOTA_LAGO";
export const FAILED_QUOTA_LAGO = "FAILED_QUOTA_LAGO";

// export function showMobileNavigationMenu() {
//   return {
//     type: SHOW_MOBILE_NAVIGATION_MENU,
//   };
// }
// export function hideMobileNavigationMenu() {
//   return {
//     type: HIDE_MOBILE_NAVIGATION_MENU,
//   };
// }

export const fetchQuotaLago = () => dispatch => {
  dispatch(loadingQuotaLago(true));

  // return fetch(rainAnnuario_url)
  return fetch(quotaLagoUrl + "quotalago.json")
    .then(
      response => {
        if (response.ok) {
          // console.log(response);
          return response;
        } else {
          var error = new Error("Error" + response.status + ": " + response.statusText);
          error.response = response;
          throw error;
        }
      },
      error => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then(response => response.json())
    .then(quotaLago => dispatch(addQuotaLago(quotaLago)))
    .catch(error => dispatch(failedQuotaLago(error.message)));
};

export const loadingQuotaLago = () => ({
  type: LOADING_QUOTA_LAGO,
});

export const failedQuotaLago = errmess => ({
  type: FAILED_QUOTA_LAGO,
  payload: errmess,
});

export const addQuotaLago = quotaLago => ({
  type: ADD_QUOTA_LAGO,
  payload: quotaLago,
});
