import i18n from "i18next";
import {initReactI18next} from "react-i18next";

i18n.use(initReactI18next).init({
  // we init with resources
  resources: {
    it: {
      translations: {
        Lun: "Lun",
        Mar: "Mar",
        Mer: "Mer",
        Gio: "Gio",
        Ven: "Ven",
        Sab: "Sab",
        Dom: "Dom",
        Grafico: "Grafico",
        Altezza: "Altezza",
        Tempo: "Tempo",
        "Quota attuale": "Quota attuale",
        Andamento: "Andamento",
        "Quota massima prevista": "Quota massima prevista",
        "Valori misurati": "Valori misurati",
        "Altezza prevista": "Altezza prevista",
        "Grado 1": "Grado 1",
        "Grado 2": "Grado 2",
        "Grado 3": "Grado 3",
        "Grado 4/5": "Grado 4/5",
        Allarme: "Allarme",
        Statistiche: "Statistiche",
        Nessuno: "Nessuno",
        Mappa: "Mappa",
        "Area riservata": "Area riservata",
      },
    },
    fr: {
      translations: {
        Lun: "Lun",
        Mar: "Mar",
        Mer: "Mer",
        Gio: "Jeu",
        Ven: "Ven",
        Sab: "Sam",
        Dom: "Dim",
        Grafico: "Graphique",
        Altezza: "Hauteur",
        Tempo: "Temps",
        "Quota attuale": "Altitude actuelle",
        Andamento: "Tendance",
        "Quota massima prevista": "Altitude maximale prévue",
        "Valori misurati": "Valeurs mesurées",
        "Altezza prevista": "Altitude prévue",
        "Grado 1": "Grade 1",
        "Grado 2": "Grade 2",
        "Grado 3": "Grade 3",
        "Grado 4/5": "Grade 4/5",
        Allarme: "Alarme",
        Statistiche: "Statistiques",
        Nessuno: "Aucun",
        Mappa: "Carte",
        "Area riservata": "Zone réservée",
      },
    },
    de: {
      translations: {
        Lun: "Mon",
        Mar: "Die",
        Mer: "Mit",
        Gio: "Don",
        Ven: "Fre",
        Sab: "Sam",
        Dom: "Son",
        Grafico: "Grafik",
        Altezza: "Höhe",
        Tempo: "Zeit",
        "Quota attuale": "Aktuelle Höhe",
        Andamento: "Trend",
        "Quota massima prevista": "Vorgesehene maximaler Höhe",
        "Valori misurati": "Gemessene Werte",
        "Altezza prevista": "Geplante Höhe",
        "Grado 1": "1.Klasse",
        "Grado 2": "2.Klasse",
        "Grado 3": "3.Klasse",
        "Grado 4/5": "4/5.Klasse",
        Allarme: "Alarm",
        Statistiche: "Statistik",
        Nessuno: "Niemand",
        Mappa: "Map",
        "Area riservata": "Reservierter Bereich",
      },
    },
    en: {
      translations: {
        Lun: "Mon",
        Mar: "Tue",
        Mer: "Wed",
        Gio: "Thu",
        Ven: "Fri",
        Sab: "Sat",
        Dom: "Sun",
        Grafico: "Chart",
        Altezza: "Height",
        Tempo: "Time",
        "Quota attuale": "Current height",
        Andamento: "Trend",
        "Quota massima prevista": "Maximum expected height",
        "Valori misurati": "Measured values",
        "Altezza prevista": "Expected height",
        "Grado 1": "1 Grade",
        "Grado 2": "2 Grade",
        "Grado 3": "3 Grade",
        "Grado 4/5": "4/5 Grade",
        Allarme: "Alarm",
        Statistiche: "Statistics",
        Nessuno: "None",
        Mappa: "Map",
        "Area riservata": "Reserved area",
      },
    },
  },
  lng: "it",
  fallbackLng: "it",
  debug: process.env.NODE_ENV !== "production",

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
